
<div class="container">
  <div class="d-flex mb-2 justify-content-between">
    <h3 class="mt-4">Query Generator</h3>
    <div class="d-flex mt-4" style="gap:10px">
      <a href="" style=" line-height: 40px;" class="ml-auto" routerLink="/" routerLinkActive="active">Rapid Mail Writer</a>
      <a href="" style=" line-height: 40px;" class="ml-auto" routerLink="/bandi" routerLinkActive="active">Caricamento Bandi</a>
    </div>
  </div>
  <form [formGroup]="firstForm">
    <input type="text" *ngIf="firstForm.value.nuovaUtenzaBool === true || firstForm.value.lastLoginBool || firstForm.value.resetPasswordBool === true || firstForm.value.sbloccaUtenzaBool === true || firstForm.value.bloccaUtenzaBool === true" class="form-control mb-2" placeholder="Nome utente Siebel" aria-label="Recipient's Siebel" aria-describedby="button-addon2" formControlName="nomeUtenteSiebel">
    <input type="text" *ngIf="firstForm.value.cambioEmailBool === true || firstForm.value.riattivaUtenzaBool === true" class="form-control mb-2" placeholder="Email" formControlName="email" aria-label="Recipient's email" aria-describedby="button-addon2">
    <ng-container *ngIf="firstForm.value.resetPasswordBool === true || firstForm.value.riattivaUtenzaBool === true || firstForm.value.nuovaUtenzaBool === true">
      <input type="text" class="form-control mb-2" placeholder="Password" aria-label="Recipient's Password" aria-describedby="button-addon2" formControlName="password" (change)="setInLocalStorage()">
    </ng-container>

    <div class="form-check mt-4">
      <input class="form-check-input" type="checkbox" value="" id="cambioEmailBool" formControlName="bloccaUtenzaBool">
      <label class="form-check-label" for="cambioEmailBool">
        Blocca Utenza
      </label>
    </div>

    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="riattivaUtenzaBool" formControlName="sbloccaUtenzaBool">
      <label class="form-check-label" for="riattivaUtenzaBool">
        Sblocca Utenza
      </label>
    </div>

    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="resetPasswordBool" formControlName="resetPasswordBool" (change)="resetBool()">
      <label class="form-check-label" for="resetPasswordBool">
        Reset Password
      </label>
    </div>

    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="nuovaUtenzaBool" formControlName="nuovaUtenzaBool">
      <label class="form-check-label" for="nuovaUtenzaBool">
        Nuova Utenza
      </label>
    </div>

    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="lastLoginBool" formControlName="lastLoginBool">
      <label class="form-check-label" for="lastLoginBool">
        Ultimo login
      </label>
    </div>

  <div class="mt-3">
    <div class="d-flex mb-2">
      <button type="button" (click)="generateQuery()" class="btn btn-success btn-sm mt-3">Genera Query</button>
      <button type="button" class="btn btn-dark btn-sm mt-3 ml-2" (click)="copyText()"><i class="fas fa-copy"></i></button>
      <button type="button" (click)="pulisci()" class="btn btn-danger btn-sm mt-3 ml-auto"><i class="fas fa-retweet"></i></button>
    </div>

    <textarea class="form-control" id="exampleFormControlTextarea1" rows="15" formControlName="textarea">
    </textarea>
    <div style="margin-bottom: 8rem;"></div>
  </div>
</form>
</div>
