import { BandiComponent } from './pages/bandi/bandi.component';
import { QueryComponent } from './pages/query/query/query.component';
import { HomeComponent } from './pages/home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'query', component: QueryComponent},
  {path: 'bandi', component: BandiComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
