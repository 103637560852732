import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OptionsService {

constructor() { }


fullScreen(bool: boolean): any {
  if (bool === true) {
    console.log('Schermata impostata a 900p');
    localStorage.setItem('fullscreen_option', '900px');
    return '900px';
  } else {
    console.log('Full Screen');
    localStorage.setItem('fullscreen_option', '1320px');
    return '1320px';
  }
}
}
