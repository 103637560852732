<button *ngIf="fullScreen === '900px' " class="btn btn-secondary" (click)="setSettings(false)"
  style="margin: 10px; float: right;">
  <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
</button>
<button *ngIf="fullScreen === '1320px'" class="btn btn-secondary" (click)="setSettings(true)"
  style="margin: 10px; float: right;">
  <i class="fa-solid fa-down-left-and-up-right-to-center"></i>
</button>

<div class="container" [ngStyle]="{'max-width': fullScreen}">
  <div class="d-flex mb-2 justify-content-between">
    <h3 class="mt-4">Caricamento Bandi</h3>
    <div class="d-flex mt-4" style="gap:10px">
      <a href="" style=" line-height: 40px;" class="ml-auto" routerLink="/" routerLinkActive="active">Rapid Mail
        Writer</a>
      <a href="" style=" line-height: 40px;" class="ml-auto" routerLink="/query" routerLinkActive="active">Query
        Generator</a>
    </div>
  </div>

  <form [formGroup]="bandi">
    <div class="d-flex" style="gap: 10px;">
      <div class="input-group">
        <input type="text" class="form-control text-center mw-fit-content" placeholder="Prefisso ID Row"
          (change)='save()' aria-label="Text input with checkbox" formControlName="prefisso_id_row">
        <input type="number" class="form-control text-center" placeholder="Numero ID Row di partenza"aria-label="Text input with checkbox" formControlName="id_row">
      </div>

      <form [formGroup]="radio" class="d-flex" style="gap: 10px;">
        <div class="form-check form-check-inline radio m-0 d-flex">
          <input class="form-check-input" value='Default' formControlName="radio" type="radio" id="inlineCheckbox1"
            (click)="setRecordListFormat('Default')">
          <label class="form-check-label" for="inlineCheckbox1">Dafault Format</label>
        </div>
        <div class="form-check form-check-inline radio m-0 d-flex">
          <input class="form-check-input" value='E1' formControlName="radio" type="radio" id="inlineCheckbox2"
            (click)="setRecordListFormat('E1')">
          <label class="form-check-label" for="inlineCheckbox2">E1 Format</label>
        </div>
      </form>
    </div>

    <div class="input-group mb-2 mt-4">
      <div class="input-group-text" style="padding: 0;">
        <button class="fa-solid fa-plus" (click)="addBando()"></button>
      </div>
      <input type="text" class="form-control text-center" placeholder="Ambito" aria-label="Text input with checkbox"
        formControlName="ambito">
      <input type="text" class="form-control text-center mw-fit-content" placeholder="Data ed ora"
        aria-label="Text input with checkbox" formControlName="data_ora">
      <input type="text" class="form-control text-center mw-fit-content" placeholder="Marco Area" (change)='save()'
        aria-label="Text input with checkbox" formControlName="macro_area">
    </div>

    <div class="alert alert-danger" role="alert" *ngIf="errors_alert.bool === 'addBando'">
      - Compila tutti i campi.
      <br> - Ambito deve avere massimo 30 caratteri.
    </div>

    <h5 class="mt-5">Bandi da esportare</h5>
    <div class="alert alert-warning" role="alert" *ngIf="bandiList.length <= 0">
      Nessun bando caricato.
    </div>
    <div class="input-group mb-2 mt-3" *ngFor="let bando of bandiList, let id = index">
      <div class="input-group-text" style="padding: 0;">
        <button class="fa-solid fa-trash" (click)="deleteBando(id)"></button>
      </div>
      <input readonly type="text" class="form-control text-center" value='{{bando.ambito}}'
        aria-label="Text input with checkbox" formControlName="ambito">
      <input readonly type="text" class="form-control text-center" value='{{bando.data_ora}}'
        aria-label="Text input with checkbox" formControlName="data_ora">
      <input readonly type="text" class="form-control text-center" value='{{bando.macro_area}}'
        aria-label="Text input with checkbox" formControlName="macro_area">
    </div>

    <div class="d-grid">
    </div>


    <h5 *ngIf="sommario_bandi_caricati.length > 0" class="mt-4">Bandi esportati</h5>

    <div class="input-group mb-2 mt-3" *ngFor="let bando of sommario_bandi_caricati">
      <div type="text" class="form-control text-center">
        <b (click)="copyText(bando.ambito)">{{bando.ambito}}</b> Da <b (click)="copyText(bando.id_row_start)">{{bando.id_row_start}}</b> a <b (click)="copyText(bando.id_row_end)">{{bando.id_row_end}}</b>;
      </div>
    </div>

    <div class="d-flex mb-2 mt-4" style="gap:10px">
      <div class="d-flex " style="gap:10px">
          <button [disabled]="bandiList.length <= 0" class="btn btn-success btn-sm"
          (click)="exportCSV()">Esporta CSV</button>
      </div>
      <button type="button" (click)="pulisci()" class="btn btn-danger btn-sm ml-auto"><i
          class="fas fa-retweet"></i></button>
    </div>
  </form>

</div>
