import { ExcelService } from './../../excel.service';
import { Component, OnInit, Self } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ClipboardService } from 'ngx-clipboard';
import * as data from '../../../assets/csvjson.json';
import * as data_e1 from '../../../assets/csvjson_e1.json';
import * as moment from 'moment';
import { OptionsService } from 'src/app/options.service';

@Component({
  selector: 'app-bandi',
  templateUrl: './bandi.component.html',
  styleUrls: ['./bandi.component.scss'],
})
export class BandiComponent implements OnInit {
  bandi: FormGroup | any;
  radio: FormGroup | any;
  bandiList = new Array();
  recordsList = new Array();
  exportExcellSubmit = false;
  addBandoSubmit = false;
  errors_alert = { bool: 'none', msg: 'Nessun testo settato' };
  myRadio: any;

  // For option service.
  fullScreen: any = '';
  fileName = 'prova';

  constructor(
    private formBuilder: FormBuilder,
    private clipboardApi: ClipboardService,
    private excelService: ExcelService,
    private optionService: OptionsService
  ) {
    this.bandi = this.formBuilder.group({
      prefisso_id_row: new FormControl('SPV', [Validators.required]),
      id_row: new FormControl('', [Validators.required]),
      ambito: new FormControl('', [Validators.maxLength(30), Validators.required]),
      data_ora: new FormControl(moment().format('DD/MM/YYYY HH.mm.00'), [Validators.required]),
      macro_area: new FormControl('', [Validators.required]),
      radio: new FormControl(''),
    });

    this.radio = this.formBuilder.group({
      radio: new FormControl(''),
    });
  }

  ngOnInit() {
    this.setRecordListFormat('Default');

    if (localStorage.getItem('fullscreen_option') === null) {
      this.fullScreen = '1320px';
    } else {
      this.fullScreen = localStorage.getItem('fullscreen_option');
    }

    this.bandi.get('prefisso_id_row').setValue(localStorage.getItem('prefisso_id_row'));
    this.bandi.get('macro_area').setValue(localStorage.getItem('macro_area'));
  }

  setSettings(bool: boolean) {
    this.fullScreen = this.optionService.fullScreen(bool);
  }

  setRecordListFormat(format: string) {
    this.radio.get('radio').setValue(format);

    const radio = this.radio.get('radio').value;

    if (radio === 'Default') {
      this.recordsList = data.default;
    } else if (radio === 'E1') {
      this.recordsList = data_e1.default;
    }
  }

  save() {
    localStorage.setItem( 'prefisso_id_row', this.bandi.get('prefisso_id_row').value);
    localStorage.setItem('macro_area', this.bandi.get('macro_area').value);
  }

  copyText(id_row_text: string): void  {
    this.clipboardApi.copyFromContent(id_row_text);
  }

  addBando() {
    this.container = [];
    this.exportExcellSubmit = true;

    if (this.bandi.valid) {
      this.errors_alert.bool = 'none';
      const bandi = this.bandi.getRawValue();

      const bando = {
        ambito: bandi.ambito.split(' ').join(''),
        data_ora: bandi.data_ora,
        macro_area: bandi.macro_area,
      };

      this.bandiList.push(bando);
    } else {
      this.errors_alert.bool = 'addBando';
      this.errors_alert.msg = `Assicurati di aver compilato tutti i campi, le regole da seguire sono le seguenti:
      - Compila tutti i campi.
      - Ambito deve avere massimo 30 caratteri.
      - Stabilisci un ID_Row di partenza.`;
    }
  }

  pulisci(): void {
    this.bandi.get('ambito').setValue('');
    this.bandi.get('id_row').setValue('');

    this.bandi.get('data_ora').setValue(moment().format('DD/MM/YYYY HH.mm.00'));
    this.bandiList = new Array();
    this.errors_alert.bool = 'none';
    this.sommario_bandi_caricati = [];
  }

  deleteBando(index: any) {
    this.bandiList.splice(index, 1);
  }

  container = new Array();
  tot_records = 0;
  last_id_row: any = 0;
  sommario_bandi_caricati = new Array();


  exportCSV() {
    this.container = [];
    this.sommario_bandi_caricati = [];

    if (this.bandiList.length > 0) {
      this.bandi.updateValueAndValidity();

      const bandi = this.bandi.getRawValue();

      let i = 0;
      let bandi_offset = 0;
      let bandi_counter = bandi.id_row;

      while (i < this.bandiList.length) {
        bandi_offset += this.recordsList.length;

        this.sommario_bandi_caricati.push({
          ambito: this.bandiList[i].ambito,
          id_row_start: bandi.prefisso_id_row + (bandi_counter + 1),
          id_row_end: undefined,
        });

        const newRecord = this.recordsList.map((value) => {
          bandi_counter++;
          value.id_row = bandi.prefisso_id_row + bandi_counter;
          value.ambito = this.bandiList[i].ambito;
          value.data_ora = bandi.data_ora;
          return { ...value };
        });

        this.sommario_bandi_caricati[i].id_row_end = bandi.prefisso_id_row + bandi_counter;

        this.container.push([...newRecord]);
        i++;
      }

      if (this.sommario_bandi_caricati.length < 2) {
        this.fileName = this.sommario_bandi_caricati.length + '_Bando_' + moment().format('DDMMYYYY');
      } else {
        this.fileName = this.sommario_bandi_caricati.length + '_Bandi_' +  moment().format('DDMMYYYY');
      }

      const merged_container: any = [].concat.apply([], this.container);

      this.excelService.exportCsv(merged_container, this.fileName);

      this.tot_records = merged_container.length;
      this.last_id_row = merged_container[merged_container.length - 1].id_row;
    }
  }
}
