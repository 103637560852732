import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ClipboardService } from 'ngx-clipboard';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  // Reactive Form Validations
  firstForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private clipboardApi: ClipboardService) {
    this.firstForm = this.formBuilder.group({
      richiedente: new FormControl(''),
      nomeDestinatario: new FormControl(''),
      nomeUtenteSiebel: new FormControl(''),
      password: new FormControl(''),
      email: new FormControl(''),
      gruppoAttestatoUtenza: new FormControl(''),
      firma: new FormControl(''),
      textarea: new FormControl(''),
      linkSiebel: new FormControl(''),
      linkSiebelDesc: new FormControl(''),
      numeroTicket: new FormControl(''),
      idEmail: new FormControl(''),
      infoAggiuntivePassword: new FormControl(false),
      cambioEmailBool: new FormControl(false),
      resetPasswordBool: new FormControl(false),
      nuovaUtenzaBool: new FormControl(false),
      riattivaUtenzaBool: new FormControl(false),
      nuovoBandoBool: new FormControl(false),
      richiedenteBool: new FormControl(false),
      linkSiebelBool: new FormControl(false),
      dissociaEmailBool: new FormControl(false)
    });
  }

  ngOnInit(): void {
    this.firstForm.get('firma')?.setValue(localStorage.getItem('firma'));
    this.firstForm.get('linkSiebel')?.setValue(localStorage.getItem('linkSiebel'));
    this.firstForm.get('linkSiebelDesc')?.setValue(localStorage.getItem('linkSiebelDesc'));
  }

  generateEmail(): void {
    const mailElement = this.firstForm.getRawValue();
    const contentObject = {
      saluto: `Buongiorno ${mailElement.nomeDestinatario},`,
      richiedente: `\n\ncome richiesto da ${mailElement.richiedente} `,
      noRichiedente: `\n\ncome richiesto `,
      nuovaUtenza: `abbiamo creato la sua utenza (${mailElement.nomeUtenteSiebel}) per l’utilizzo della piattaforma Siebel.\nAbbiamo associato al suo profilo per il reset della password in autonomia, l’indirizzo e-mail ${mailElement.email}`,
      riattivaUtenza: `abbiamo riattivato la sua utenza Siebel (${mailElement.nomeUtenteSiebel}), effettuando un reset della sua password di accesso alla Piattaforma. `,
      gruppoUtenza: `\nAbbiamo attestato la sua utenza sul Gruppo denominato ${mailElement.gruppoAttestatoUtenza}.`,
      nuovaPassword: `abbiamo resettato la password d'accesso alla piattaforma Siebel per il profilo indicato. \n\nSegnaliamo che il reset in autonomia della password non è consentito, nel caso in cui non si sia effettuato un accesso a Siebel da più di 15 giorni. `,
      cambioMail: `abbiamo applicato la modifica della mail al profilo Siebel ${mailElement.nomeUtenteSiebel} (${mailElement.nomeDestinatario}) associando la seguente email: ${mailElement.email}\n`,
      credenzialiDiAccesso: `\n\nLe credenziali di accesso sono: \nUtente: ${mailElement.nomeUtenteSiebel}\nPassword: ${mailElement.password}`,
      infoUtenza: `\n\nInformazioni Utenza: \nEmail: ${mailElement.email}\nGruppo attestato: ${mailElement.gruppoAttestatoUtenza}`,
      infoPassword: `\n\nSecondo lo standard di sicurezza ARIA S.p.A., è obbligatorio il cambio della password al primo accesso.\nLa nuova password avrà una validità di 90 giorni (consigliamo di cambiarla prima della scadenza) e dovrà rispettare le caratteristiche elencate di seguito:\n\n- Lunghezza minima di 8 caratteri alfanumerici con una maiuscola, una minuscola ed un carattere speciale ad eccezione della @.\n- Non deve contenere riferimenti a Login, Nome e Cognome.\n- Non deve essere uguale alle ultime 3 password.\n- Non deve essere uguale alla password scelta negli ultimi 180 giorni.\n- Non si possono usare due caratteri consecutivi identici.`,
      linkSiebel: `\n\n${mailElement.linkSiebelDesc}: ${mailElement.linkSiebel}`,
      arrivederci: `\n\nRestiamo a disposizione. \n\nCordiali saluti,\n${mailElement.firma}`,
      dissociaEmail: `la email con ID ${mailElement.idEmail} è stata dissociata dal ticket N. ${mailElement.numeroTicket}.`,
      nuovoBando: `le classificazioni del bando in oggetto sono state censite e configurate in Siebel.`
    };

    let content = contentObject.saluto;

    // tslint:disable-next-line: max-line-length
    if (mailElement.richiedenteBool === true){ content = content + contentObject.richiedente; } else {content = content + contentObject.noRichiedente; }
    if (mailElement.cambioEmailBool === true){content = content + contentObject.cambioMail; }
    if (mailElement.resetPasswordBool === true){content = content + contentObject.nuovaPassword + contentObject.credenzialiDiAccesso; }
    if (mailElement.nuovaUtenzaBool === true){ content = content + contentObject.nuovaUtenza + contentObject.credenzialiDiAccesso; }
    if (mailElement.riattivaUtenzaBool === true){ content = content + contentObject.riattivaUtenza + contentObject.credenzialiDiAccesso;  }
    if (mailElement.infoAggiuntivePassword === true){ content = content + contentObject.infoPassword; }
    if (mailElement.linkSiebelBool === true){ content = content + contentObject.linkSiebel; }
    if (mailElement.dissociaEmailBool === true){content = content + contentObject.dissociaEmail;}
    if (mailElement.nuovoBandoBool === true){content = content + contentObject.nuovoBando;}

    content = content + contentObject.arrivederci;
    this.firstForm.get('textarea')?.setValue(content);
  }

  pulisci(): void {
    this.firstForm.reset();
    this.firstForm.get('firma')?.patchValue(localStorage.getItem('firma'));
    this.firstForm.get('linkSiebel')?.patchValue(localStorage.getItem('linkSiebel'));
    this.firstForm.get('linkSiebelDesc')?.patchValue(localStorage.getItem('linkSiebelDesc'));
  }

  setFirma(): void {
    localStorage.setItem('firma', this.firstForm.get('firma')?.value);
  }

  setSiebelLink(): void {
    if (this.firstForm.get('linkSiebel')?.value === null){
      localStorage.setItem('linkSiebel', '');
    } else {
      localStorage.setItem('linkSiebel', this.firstForm.get('linkSiebel')?.value);
    }

    if (this.firstForm.get('linkSiebelDesc')?.value === null){
      localStorage.setItem('linkSiebelDesc', '');
    } else {
      localStorage.setItem('linkSiebelDesc', this.firstForm.get('linkSiebelDesc')?.value);
    }
  }

  copyText(): void  {
    this.clipboardApi.copyFromContent(this.firstForm.get('textarea')?.value);
  }

  resetBool(): void {
    const mailElement = this.firstForm.getRawValue();
    if (mailElement.resetPasswordBool !== true){
      this.firstForm.get('infoAggiuntivePassword')?.setValue(false);
    }
  }
}
