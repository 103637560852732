import { Injectable } from '@angular/core';
import { ngxCsv } from 'ngx-csv/ngx-csv';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { }
  exportCsv(container: any, filename: string){

    const options: any = {
      fieldSeparator: ';',
      quoteStrings: false,
      decimalseparator: '.',
      showLabels: false,
      showTitle: false,
      useBom: false,
      noDownload: false,
      headers: false,
    };

    // tslint:disable-next-line: no-unused-expression
    new ngxCsv(container, filename, options);
  }
}
