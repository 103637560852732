
<div class="container">
  <div class="d-flex mb-2 justify-content-between">
    <h3 class="mt-4">Rapid Mail Writer</h3>
    <div class="d-flex mt-4" style="gap:10px">
      <a href="" style=" line-height: 40px;" class="ml-auto" routerLink="/query" routerLinkActive="active">Query Generator</a>
      <a href="" style=" line-height: 40px;" class="ml-auto" routerLink="/bandi" routerLinkActive="active">Caricamento Bandi</a>
    </div>
  </div>
  <form [formGroup]="firstForm">
    <div class="input-group mb-2 mt-4">
      <div class="input-group-text">
        <input class="form-check-input mt-0" type="checkbox" value="" aria-label="Checkbox for following text input" formControlName="richiedenteBool">
      </div>
      <input type="text" class="form-control" placeholder="Richiedente" aria-label="Text input with checkbox" formControlName="richiedente" [readonly]="this.firstForm.value.richiedenteBool !== true">
    </div>
    <input type="text" class="form-control mb-2" placeholder="Nome destinatario" aria-label="Recipient's Nome" aria-describedby="button-addon2" formControlName="nomeDestinatario">
    <input type="text" *ngIf="firstForm.value.nuovaUtenzaBool === true || firstForm.value.cambioEmailBool === true || firstForm.value.resetPasswordBool === true || firstForm.value.riattivaUtenzaBool === true" class="form-control mb-2" placeholder="Nome utente Siebel" aria-label="Recipient's Siebel" aria-describedby="button-addon2" formControlName="nomeUtenteSiebel">
    <input type="text" *ngIf="firstForm.value.cambioEmailBool === true || firstForm.value.nuovaUtenzaBool === true || firstForm.value.riattivaUtenzaBool === true" class="form-control mb-2" placeholder="Email" formControlName="email" aria-label="Recipient's email" aria-describedby="button-addon2">
    <input type="text" *ngIf="firstForm.value.riattivaUtenzaBool === true" class="form-control mb-2" placeholder="Gruppo attestato all'Utenza (TERRITORIO 7034)" formControlName="gruppoAttestatoUtenza" aria-label="Recipient's Gruppo" aria-describedby="button-addon2">
    <input type="text" *ngIf="firstForm.value.dissociaEmailBool=== true" class="form-control mb-2" placeholder="ID Email" formControlName="idEmail" aria-label="Recipient's Gruppo" aria-describedby="button-addon2">
    <input type="text" *ngIf="firstForm.value.dissociaEmailBool=== true" class="form-control mb-2" placeholder="N° Ticket" formControlName="numeroTicket" aria-label="Recipient's Gruppo" aria-describedby="button-addon2">
    <ng-container *ngIf="firstForm.value.resetPasswordBool === true || firstForm.value.nuovaUtenzaBool === true || firstForm.value.riattivaUtenzaBool === true">
      <input type="text" class="form-control mb-2" placeholder="Password" aria-label="Recipient's Password" aria-describedby="button-addon2" formControlName="password">
      <div class="form-check mb-3">
        <input class="form-check-input" type="checkbox" id="infoAggiuntivePassword" formControlName="infoAggiuntivePassword">
        <label class="form-check-label" for="infoAggiuntivePassword">
          Informazioni aggiuntive password
        </label>
      </div>
    </ng-container>
    <div class="input-group mb-2 mt-4">
      <div class="input-group-text">
        <input class="form-check-input mt-0" type="checkbox" value="" aria-label="Checkbox for following text input" formControlName="linkSiebelBool">
      </div>
      <input type="text" class="form-control" placeholder="Descrizione link" aria-label="Text input with checkbox" formControlName="linkSiebelDesc" [readonly]="this.firstForm.value.linkSiebelBool !== true" (change)="setSiebelLink()">
      <input type="text" class="form-control" placeholder="Inserisci link" aria-label="Text input with checkbox" formControlName="linkSiebel" [readonly]="this.firstForm.value.linkSiebelBool !== true" (change)="setSiebelLink()">
    </div>
    <label class="form-label mt-2 mb-1">Firma dell'amministratore</label>
    <input type="text" class="form-control" placeholder="Firma" aria-label="Recipient's username" aria-describedby="button-addon2" formControlName="firma" (change)="setFirma()">

    <div class="form-check mt-4">
      <input class="form-check-input" type="checkbox" value="" id="cambioEmailBool" formControlName="cambioEmailBool">
      <label class="form-check-label" for="cambioEmailBool">
        Cambio Email
      </label>
    </div>

    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="dissociaEmailBool" formControlName="dissociaEmailBool">
      <label class="form-check-label" for="dissociaEmailBool">
        Dissocia Email
      </label>
    </div>

    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="resetPasswordBool" formControlName="resetPasswordBool" (change)="resetBool()">
      <label class="form-check-label" for="resetPasswordBool">
        Reset Password
      </label>
    </div>

    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="nuovaUtenzaBool" formControlName="nuovaUtenzaBool">
      <label class="form-check-label" for="nuovaUtenzaBool">
        Nuova utenza Siebel
      </label>
    </div>

    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="riattivaUtenzaBool" formControlName="riattivaUtenzaBool">
      <label class="form-check-label" for="riattivaUtenzaBool">
        Riattiva utenza Siebel
      </label>
    </div>

    <div class="form-check">
      <input class="form-check-input" type="checkbox" value="" id="nuovoBandoBool" formControlName="nuovoBandoBool">
      <label class="form-check-label" for="nuovoBandoBool">
        Nuovo Bando
      </label>
    </div>

  <div class="mt-3">
    <div class="d-flex mb-2">
      <button type="button" (click)="generateEmail()" class="btn btn-success btn-sm mt-3">Genera Email</button>
      <button type="button" class="btn btn-dark btn-sm mt-3 ml-2" (click)="copyText()"><i class="fas fa-copy"></i></button>
      <button type="button" (click)="pulisci()" class="btn btn-danger btn-sm mt-3 ml-auto"><i class="fas fa-retweet"></i></button>
    </div>

    <textarea class="form-control" id="exampleFormControlTextarea1" rows="15" formControlName="textarea">
    </textarea>
    <div style="margin-bottom: 8rem;"></div>
  </div>
</form>
</div>
