import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ClipboardService } from 'ngx-clipboard';

declare var $: any;

@Component({
  selector: 'app-query',
  templateUrl: './query.component.html',
  styleUrls: ['./query.component.scss']
})

export class QueryComponent implements OnInit {

  // Reactive Form Validations
  firstForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private clipboardApi: ClipboardService) {
    this.firstForm = this.formBuilder.group({
      nomeUtenteSiebel: new FormControl(''),
      password: new FormControl(''),
      sbloccaUtenzaBool: new FormControl(false),
      bloccaUtenzaBool: new FormControl(false),
      resetPasswordBool: new FormControl(false),
      nuovaUtenzaBool: new FormControl(false),
      lastLoginBool: new FormControl(false),
      textarea: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.firstForm.get('firma')?.setValue(localStorage.getItem('firma'));
    this.firstForm.get('linkSiebel')?.setValue(localStorage.getItem('linkSiebel'));
    this.firstForm.get('linkSiebelDesc')?.setValue(localStorage.getItem('linkSiebelDesc'));
    this.firstForm.get('password')?.setValue(localStorage.getItem('password'));
  }

  generateQuery(): void {
    const mailElement = this.firstForm.getRawValue();
    const contentObject = {
      nuovaUtenza: `CREATE USER ${mailElement.nomeUtenteSiebel.toUpperCase().trim()} IDENTIFIED BY ${mailElement.password} PROFILE CONTROLLO_PASSWD TEMPORARY TABLESPACE TEMP DEFAULT TABLESPACE TS_AF01D;\n\nGRANT SSE_ROLE TO ${mailElement.nomeUtenteSiebel.toUpperCase().trim()};\n\nALTER USER ${mailElement.nomeUtenteSiebel.toUpperCase().trim()} DEFAULT ROLE SSE_ROLE;\n\nUPDATE SIEBEL.S_USER SET PW_LAST_UPD=SYSDATE,TRNG_DL_ADMIN_FLG = 'Y' WHERE LOGIN= '${mailElement.nomeUtenteSiebel.toUpperCase().trim()}';`,
      nuovaPassword: `ALTER USER ${mailElement.nomeUtenteSiebel.toUpperCase().trim()} ACCOUNT UNLOCK;\n\nUPDATE SIEBEL.S_USER SET PW_LAST_UPD=SYSDATE,\n\nTRNG_DL_ADMIN_FLG = 'Y' WHERE LOGIN= '${mailElement.nomeUtenteSiebel.toUpperCase().trim()}';\n\nALTER USER ${mailElement.nomeUtenteSiebel.toUpperCase().trim()} IDENTIFIED BY ${mailElement.password};\n\nALTER USER ${mailElement.nomeUtenteSiebel.toUpperCase().trim()} DEFAULT ROLE SSE_ROLE;`,
      sbloccaUtenza: `ALTER USER ${mailElement.nomeUtenteSiebel.toUpperCase().trim()} ACCOUNT UNLOCK;`,
      bloccaUtenza: `ALTER USER ${mailElement.nomeUtenteSiebel.toUpperCase().trim()} ACCOUNT LOCK;`,
      lastLogin: `select LAST_LOGIN_TS from SIEBEL.S_USER where LOGIN = '${mailElement.nomeUtenteSiebel.toUpperCase().trim()}';`,
      commit: `\n\nCOMMIT;`,
    };

    let content = '';

    // tslint:disable-next-line: max-line-length
    if (mailElement.resetPasswordBool === true){content = content + contentObject.nuovaPassword; }
    if (mailElement.nuovaUtenzaBool === true){ content = content + contentObject.nuovaUtenza; }
    if (mailElement.sbloccaUtenzaBool === true){ content = content + contentObject.sbloccaUtenza; }
    if (mailElement.bloccaUtenzaBool === true){ content = content + contentObject.bloccaUtenza; }
    if (mailElement.lastLoginBool === true){ content = content + contentObject.lastLogin; }

    content = content + contentObject.commit;
    this.firstForm.get('textarea')?.setValue(content);
  }

  pulisci(): void {
    this.firstForm.reset();
    this.firstForm.get('firma')?.patchValue(localStorage.getItem('firma'));
    this.firstForm.get('linkSiebel')?.patchValue(localStorage.getItem('linkSiebel'));
    this.firstForm.get('linkSiebelDesc')?.patchValue(localStorage.getItem('linkSiebelDesc'));
    this.firstForm.get('password')?.patchValue(localStorage.getItem('password'))
  }

  setInLocalStorage(): void {
    localStorage.setItem('password', this.firstForm.get('password')?.value);
  }

  setSiebelLink(): void {
    if (this.firstForm.get('linkSiebel')?.value === null){
      localStorage.setItem('linkSiebel', '');
    } else {
      localStorage.setItem('linkSiebel', this.firstForm.get('linkSiebel')?.value);
    }

    if (this.firstForm.get('linkSiebelDesc')?.value === null){
      localStorage.setItem('linkSiebelDesc', '');
    } else {
      localStorage.setItem('linkSiebelDesc', this.firstForm.get('linkSiebelDesc')?.value);
    }
  }

  copyText(): void  {
    this.clipboardApi.copyFromContent(this.firstForm.get('textarea')?.value);
  }

  resetBool(): void {
    const mailElement = this.firstForm.getRawValue();
    if (mailElement.resetPasswordBool !== true){
      this.firstForm.get('infoAggiuntivePassword')?.setValue(false);
    }
  }
}
